<template>
  <div
    eagle-video-player-kernel="youtube"
    :style="containerStyle"
  >
    <div :id="elementId"></div>
  </div>
</template>

<script>
import uuidV4 from 'uuid/v4'
import videoPlayerKernel from 'components/videoPlayer/playerKernel/videoPlayerKernel'
export default {
  mixins: [videoPlayerKernel],
  data: () => ({
    hasPlay: false,
  }),
  mounted() {
    this.setup()
  },
  methods: {
    async setup() {
      await this.$nextTick()
      if(window.YT) {
        this.$nextTick(() => {
          this.onYouTubeIframeAPIReady()
        })
        return
      }

      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      this.$nextTick(() => {
        this.onYouTubeIframeAPIReady()
      })
    },
    onYouTubeIframeAPIReady() {
      if(!window.YT) {
        setTimeout(() => {
          this.onYouTubeIframeAPIReady()
        }, 500)
        return
      }
      window.YT.ready(()=> {
        this.player = new YT.Player(this.elementId, {
          height: '100%',
          width: '100%',
          videoId: this.youtubeUid,
          playerVars: {
            autoplay: this.autoplay === true ? 1 : 0,
            start: !!this.startTime ? this.startTime : undefined,
          },
          events: {
            'onStateChange': this.onPlayerStateChange
          },
        })
      })
    },
    setupGetCurrentTimeCallback(event) {
      if(typeof this.getCurrentTimeCallback === 'function') return
      this.getCurrentTimeCallback = () => event.target.getCurrentTime()
    },
    onPlayerStateChange(event) {
      this.setupGetCurrentTimeCallback(event)
      if(event.data == YT.PlayerState.PLAYING) {
        if(this.hasPlay === false) {
          this.hasPlay = true
          this.onVideoFirstPlay()
        }

        else {
          this.hasPlay = true
          this.onVideoPlay()
        }
      }

      else if(event.data == YT.PlayerState.BUFFERING) {

      }

      else if(event.data == YT.PlayerState.CUED) {

      }

      else if(event.data == YT.PlayerState.ENDED) {
        this.onVideoEnd()
      }

      else if(event.data == YT.PlayerState.PAUSED) {
        this.onVideoPause()
      }
    },
    pause() {
      if(!this.player) return
      if(typeof this.player.pauseVideo != 'function') return
      this.player.pauseVideo()
    },
  },
  computed: {
    youtubeUid() {
      if(!this.video) return null
      if(!this.video.youtube_url) return null
      return this.$helper.getYoutubeUid(this.video.youtube_url)
    },
    elementId() {
      return `youtube-${uuidV4()}`
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
