export default {
  props: {
    width: Number,
    height: Number,
    autoplay: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    onVideoFirstPlay() {
      this.$emit('firstPlay')
    },
    onVideoPlay() {
      this.$emit('play')
    },
    onVideoPause() {
      this.$emit('pause')
    },
    onVideoEnd() {
      this.$emit('end')
    },
    pause() {
      // TODO
    },
  },
  computed: {
    containerStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      }
    },
  },
}
